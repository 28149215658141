import * as React from "react";

import Icon from "@fitplan/lib/components/Icons";

export interface Props {
    className?: string;
    nextSlide: () => void;
}

class CarouselButtonRight extends React.Component<Props> {
    ref: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.ref = React.createRef();
    }

    handleTouchStart = () => {
        const button = this.ref.current;
        button.style.backgroundImage = `linear-gradient(
       46deg, #12b587, #29db57, #00ffb3)`;
    };

    handleTouchEnd = () => {
        const button = this.ref.current;
        button.style.backgroundImage = "none";
    };

    render() {
        return (
            <button
                className={this.props.className}
                onClick={this.props.nextSlide}
            >
                <div
                    ref={this.ref}
                    className="carousel__button"
                    onTouchStart={this.handleTouchStart}
                    onTouchEnd={this.handleTouchEnd}
                >
                    <Icon type="faChevronRight" />
                </div>
            </button>
        );
    }
}

export default CarouselButtonRight;
