export const getGoalsList = (plan, goalMap) => {
  return Object.keys(goalMap)
    .map(key => plan[key] && goalMap[key])
    .reduce((acc, e) => acc.concat(e), [])
    .filter(Boolean)
    .filter((e, i, arr) => {
      if (e === 'shred fat' && arr.includes('burn fat')) return false;
      return true;
    });
};

export const goalsLine = (plan, goalMap) => {
  const goals = getGoalsList(plan, goalMap);

  return goals.length > 1
    ? `s are to ${goals.slice(0, -1).join(', ')} and ${goals.slice(-1)}`
    : ` is to ${goals.toString()}`;
};

export const getGoalSection = (plan, goalMap) =>
  Object.keys(goalMap)
    .map(key => plan[key] && goalMap[key])
    .reduce((acc, e) => acc.concat(e), [])
    .filter(Boolean);

export const renameKeys = (obj, nums) => {
  if (nums) {
    const result = {};
    Object.keys(obj).map(key => parseInt(key.replace("num", ""))).forEach(key => result[key] = obj[`num${key}`]);
    return result;
  }
  const numberMap = {
    zero: 0,
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
    nine: 9,
    ten: 10,
    eleven: 11,
    twelve: 12,
    thirteen: 13,
    fourteen: 14,
    fifteen: 15,
    sixteen: 16,
    seventeen: 17,
  };
  const keyValues = Object.keys(obj).map(key => {
    const newKey = numberMap[key];
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
};
